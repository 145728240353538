import React from "react";
import { Link } from "react-router-dom"; // Asegúrate de tener react-router-dom instalado

function Logo() {
  return (
    <Link to="/" className="inline-flex shrink-0 ml-4" aria-label="Cruip">
      <img src="/assets/imgs/logos/logo_mercury_bg_transparent.png" alt="Cruip Logo" width={150} height={300} />
    </Link>
  );
}
export default Logo;