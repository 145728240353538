import React from "react";
import ServiceSlider from "./ServiceSlider";

export default function Banner() {
  return (
    <section
      className="flex flex-col justify-center items-center py-10 bg-[#3D437C]"
      style={{
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <ServiceSlider />
    </section>
  );
}
