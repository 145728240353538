import { CButton, CFormInput } from "@coreui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";

const CustomInputText = ({listener, placeholder, value}) => {
  const [isHovered, setIsHovered] = useState(false);

  return(
    <>
      <input
            type="text"
            placeholder={placeholder}
            className="w-[300px] mr-5 px-4 py-3 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400"
            value={value}
            style={{ color: '#99268d' }}
            onChange={(e) => listener(e.target.value)}
        />
    </>
  );
};

CustomInputText.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default CustomInputText;
