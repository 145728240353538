import React from 'react';
import { CModal, CModalBody, CModalHeader, CModalFooter } from '@coreui/react';
import StatusItem from './StatusItem';
import CustomButton from '../CustomButton/CustomButton';

function TrackingModal({ visible, handleClose, data }) {
  const styles = {
    modalBody: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      maxWidth: '100%',
      height: '100%',
      overflowY: 'auto',
      padding: '2rem',
      background: 'linear-gradient(135deg, #f5f7fa, #c3cfe2)',
    },
    dateGroupContainer: {
      border: '2px solid #99268d',
      width: '100%',
      maxWidth: '450px',
      boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
      borderRadius: '12px',
      backgroundColor: '#ffffff',
      padding: '1.5rem',
      marginBottom: '1.5rem',
      transition: 'transform 0.3s ease',
    },
    fontBold: {
      fontWeight: '600',
      marginBottom: '0.75rem',
      color: '#2c2c2c',
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '1rem',
      backgroundColor: '#f9f9f9',
    },
    header: {
      backgroundColor: '#f5f7fa', // Fondo suave para el header
      padding: '1rem',
      textAlign: 'center',
      borderBottom: '4px solid #99268d', // Borde inferior en color primario
    },
    headerTitle: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: '600',
      fontSize: '1.5rem', // Tamaño más grande
      color: '#99268d', // Color primario en el título
    },
  };

  return (
    <CModal visible={visible} onClose={handleClose}>
      <CModalHeader style={styles.header} onClose={handleClose}>
        <div style={styles.headerTitle}>Historial de despacho</div>
      </CModalHeader>
      <CModalBody style={styles.modalBody}>
        {data.map((_data, index) => (
          <div
            key={index}
            style={styles.dateGroupContainer}
            onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.02)')}
            onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
          >
            <div style={styles.fontBold}>{_data.date}</div>

            {_data.data.map((_data2, index) => (
              <StatusItem
                key={index}
                time={_data2.time}
                status={_data2.deliveryStatus}
                text={_data2.deliveryFrontMessage}
              />
            ))}
          </div>
        ))}
      </CModalBody>
      <CModalFooter style={styles.footer}>
        <CustomButton onClickEvent={handleClose} text='Cerrar' />
      </CModalFooter>
    </CModal>
  );
}

export default TrackingModal;
