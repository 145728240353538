import React, { useState } from 'react';
import Logo from "./Logo";
import CustomButton from './CustomButton/CustomButton';
import CustomInputText from './CustomInputText/CustomInputText';
import { getByTrackingId } from '../services/Services';
import TrackingModal from './ViewTrackingModal/TrackingModal';
import '../css/Header.css';
import TrackingErrorModal from './ViewTrackingModal/TrackingErrorModal';
const Header = () => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);

  const handleLogin = () => {
    window.location.href = "https://tracking.mercuryenvios.com"; // Redirige a la página
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(`Tracking number: ${trackingNumber}`);
    try{
    const response = await getByTrackingId(trackingNumber);
    if (response.data.length > 0) {
      setData(response.data);
      setVisible(true);
      console.log("OK");
    }
    }catch(error){
      console.log("Error");
      setErrorVisible(true);
    }
  };

  const toggleModal = () => {
    setVisible(false); // Alterna la visibilidad del modal
  };

  const toggleModalError = () => {
    setErrorVisible(false); // Alterna la visibilidad del modal
  };

  return (
    <header className="z-50 w-full bg-gray-800">
      <div className="px-4 sm:px-6 max-w-full bg-transparent">
        <div className="relative flex h-20 items-center justify-between gap-3 rounded-2xl px-3 bg-transparent">
          
          {/* Logo e input */}
          <div className="flex items-center flex-grow">
            <Logo />
            <div className="flex items-center ml-4">
              {/* El input y el botón están dentro del mismo contenedor */}
              <CustomInputText 
                placeholder="Ingresa número de seguimiento"
                value={trackingNumber}
                listener={setTrackingNumber}
              />
              <CustomButton text='Buscar Envío' onClickEvent={handleSubmit}/>       
            </div>
          </div>
          
          {/* Botón Login */}
          <div className="ml-4">
            <CustomButton text='Login' onClickEvent={handleLogin} />
          </div>
        </div>
      </div>
      <TrackingModal handleClose={toggleModal} visible={visible} data={data}/>
      <TrackingErrorModal handleClose={toggleModalError} visible={errorVisible} />
    </header>
  );
}

export default Header;
