import React from 'react';
import BannerContainer from './BannerContainer';

function Content() {
  return (
    <main className="h-[700px] w-full">
      <BannerContainer />
    </main>
  );
}

export default Content;
