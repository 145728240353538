import Slider from "react-slick";

function ServiceCard({ imgSrc, title, description }) {
  const textStyleWithBorder = {
    color: "#FFFFFF", // Cambiamos el color del texto a blanco para mejor legibilidad
    textShadow: "1px 1px 2px #99268d", // Contorno sutil pero visible con tu color primario
  };

  return (
    <div
      className="flex shadow-xl h-[400px] gap-x-4 p-6 rounded-lg transition-transform transform hover:scale-105 hover:shadow-2xl duration-300 ease-in-out"
      // Añadimos las clases para hover: escala y sombra aumentada
      style={{ background: "#3D437C", boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)" }}
    >
      <div className="mr-5"/>
      <div className="w-2/6 flex flex-col justify-center">
        <h1 className="text-3xl font-bold" style={textStyleWithBorder}>
          {title}
        </h1>
        <p className="ml-2 text-xl" style={textStyleWithBorder}>
          {description}
        </p>
      </div>
      <div className="w-4/6 flex items-center justify-center">
        <img src={imgSrc} alt={title} className="max-w-[350px] h-auto object-contain" />
      </div>
    </div>
  );
}

export default function ServiceSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <div className="w-3/4 mx-auto h-full items-center justify-center mt-8">
      <Slider {...settings}>
        <ServiceCard
          imgSrc="/assets/imgs/others/slider-one.png"
          title="Envíos Rápidos"
          description="Garantizamos que tus pedidos lleguen el mismo día con puntualidad y confiabilidad."
        />
        <ServiceCard
          imgSrc="/assets/imgs/others/slider-two.png"
          title="Simple y fácil"
          description="Ofrecemos una plataforma amigable y cómoda para que gestionar tus envíos sea más fácil que nunca."
        />
        <ServiceCard
          imgSrc="/assets/imgs/others/slider-three.png"
          title="Seguro"
          description="Contamos con respaldo y evidencia de sus entregas, en caso de daños o extravío se hará la devolución total."
        />
        <ServiceCard
          imgSrc="/assets/imgs/others/slider-four.png"
          title="Evolución"
          description="Constantemente nos adaptamos mediante la integración constante de nuevas formas y enfoques."
        />
      </Slider>
    </div>
  );
}
