import axios from 'axios';
import config from '../config';

const API_URL = `${config.API_BASE_URL}/api/tracking`;  // Cambia esta URL por tu endpoint real

// Función que realiza la solicitud al endpoint
export const getByTrackingId = async (trackingId) => {
  try {
    const response = await axios.get(`${API_URL}?trackingId=${trackingId}`);  // Cambia /your-endpoint por la ruta correcta
    return response.data;
  } catch (error) {
    console.error("Error al llamar al endpoint", error);
    throw error;
  }
};
