import { CButton } from "@coreui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";

const CustomButton = ({ onClickEvent, text }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      type="button"
      className={`${
        isHovered ? "bg-[#8a268d]" : "bg-[#9C278F]"
      } text-white font-semibold py-3 px-4 rounded-full transition-all duration-300 ease-in-out`}
      onClick={onClickEvent}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {text}
    </button>
  );
};


CustomButton.propTypes = {
  onClickEvent: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default CustomButton;
