import React from "react";
import Banner from "./Banner";

export default function BannerContainer() {
  return (
    <section className="items-center justify-center bg-[#3D437C] py-10">
      <Banner />
    </section>
  );
}
