import React from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';  // Usa los íconos que necesites

function StatusItem({ time, status, text }) {
  // Determinamos el ícono en base al estado
  const getIcon = () => {
    switch (status) {
      case 7:
        return <FaCheckCircle color="green" size={24} />;
      case 8:
        return <FaTimesCircle color="red" size={24} />;
      default:
        return <FaCheckCircle color="gray" size={24} />;
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.time}>{time}</div>
      <div style={styles.iconContainer}>
        {getIcon()}
      </div>
      <div style={styles.textContainer}>
        <div style={styles.statusText}>{text}</div>
        <div style={styles.locationText}></div>
      </div>
    </div>
  );
}

// Estilos básicos en línea, puedes adaptarlos a CSS o Tailwind si prefieres
const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
  },
  time: {
    marginRight: '10px',
    fontSize: '14px',
    color: '#666',
    marginBottom: '2px',
  },
  iconContainer: {
    marginRight: '10px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  statusText: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  locationText: {
    fontSize: '14px',
    color: '#888',
  },
};

export default StatusItem;
