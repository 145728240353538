import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom'; // Importar el enrutador
import Header from './components/Header';
import MainSection from './components/MainSection';
import Footer from './components/Footer';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '@coreui/coreui/dist/css/coreui.min.css';

function App() {
  return (
    <Router>
        <MainSection />
    </Router>
  );
}

export default App;
