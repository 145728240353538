import React from 'react';
import Content from './Content';
import Header from './Header';
import Footer from './Footer';

function MainSection() {
  return (
    <div className="main-section">
      <Header />
      <Content />
      <Footer />
    </div>
  );
}

export default MainSection;
