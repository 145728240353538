import React from 'react';
import { CModal, CModalBody, CModalHeader, CModalFooter } from '@coreui/react';
import CustomButton from '../CustomButton/CustomButton';

function TrackingErrorModal({ visible, handleClose }) {
  const styles = {
    modalBody: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      maxWidth: '100%',
      height: '100%',
      overflowY: 'auto',
      padding: '2rem',
      background: 'linear-gradient(135deg, #f5f7fa, #e0e7ff)', // Fondo más suave
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)', // Sombra suave para elevar el modal
      borderRadius: '12px', // Bordes redondeados suaves
    },
    fontBold: {
      fontWeight: '600',
      marginBottom: '0.75rem',
      color: '#2c2c2c',
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '1rem',
      backgroundColor: '#f9f9f9',
    },
    header: {
      backgroundColor: '#f5f7fa', // Fondo suave para el header
      padding: '1rem',
      textAlign: 'center',
      borderBottom: '4px solid #99268d', // Borde inferior en color primario
    },
    headerTitle: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: '600',
      fontSize: '1.5rem', // Tamaño más grande
      color: '#99268d', // Color primario en el título
    },
    bodyTitle: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: '600',
      fontSize: '1.3rem', // Tamaño más grande
      color: '#8a268d', // Color primario en el título
      textAlign: 'center', // Alineación centrada
      marginTop: '1rem',
    },
    bodyParagraph: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: '400',
      fontSize: '1rem', // Tamaño más grande
      color: '#333333', // Color suave para el párrafo
      textAlign: 'center',
      marginTop: '0.75rem',
    },
    warningIcon: {
      marginBottom: '1.5rem', // Espaciado extra para el ícono
    },
    buttonStyle: {
      backgroundColor: '#e83e8c', // Color del botón principal
      borderRadius: '50px', // Bordes redondeados completos
      padding: '0.75rem 2rem',
      color: '#ffffff',
      fontWeight: '600',
      fontSize: '1rem',
      textTransform: 'uppercase',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
    },
  };

  return (
    <CModal visible={visible} onClose={handleClose}>
      <CModalHeader style={styles.header} onClose={handleClose}>
        <div style={styles.headerTitle}>Historial de despacho</div>
      </CModalHeader>
      <CModalBody style={styles.modalBody}>
        <img 
          src='/assets/imgs/icons/warning.svg' 
          alt="In Progress" 
          width="80" 
          height="80" 
          style={styles.warningIcon} 
        />
        <h2 style={styles.bodyTitle}>
          No encontramos envíos con esa Orden en nuestro sistema
        </h2>
        <p style={styles.bodyParagraph}>
          Asegúrate de haber ingresado correctamente la OS
        </p>
      </CModalBody>
      <CModalFooter style={styles.footer}>
        <CustomButton onClickEvent={handleClose} text='Cerrar' />
      </CModalFooter>
    </CModal>
  );
}

export default TrackingErrorModal;
